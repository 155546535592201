import { createApp } from 'vue'
import router from './router'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { Chart, registerables } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import 'chartjs-adapter-date-fns'
import '@popperjs/core'
import 'bootstrap/dist/js/bootstrap.esm'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'leaflet/dist/leaflet.css'
import 'vue-select/dist/vue-select.css'
import 'flatpickr/dist/flatpickr.css'

import { setEnvironment } from './shared/system-state'
import { initI18n, loadI18nMessages } from './i18n'
import { setAuthentication } from './api-config'
import { setupTitle } from './shared/use-title'
import { vueErrorHandler } from './shared/error-handler'
import { setupBeacon } from './shared/beacon'
import { setupIdleMeasurement } from './shared/page-session-duration'
import './ops-log/ops-log-logic'
import App from './App'

setAuthentication()

Chart.register(...registerables)
Chart.register(annotationPlugin)

setEnvironment()
setupTitle({ suffix: 'Mathrail' })

setupIdleMeasurement()
setupBeacon()

const { vueI18n } = initI18n()

loadI18nMessages(vueI18n)
  .then(() => {
    const app = createApp(App)
      .use(router)
      .use(vueI18n)
      .use(VueQueryPlugin, {
        enableDevtoolsV6Plugin: true,
        queryClientConfig: {
          defaultOptions: { queries: { staleTime: 2_000 } }
        }
      })
    app.mount('#app')
    app.config.errorHandler = vueErrorHandler
  })
