import axios from 'axios'
import { timeNow } from './fns'
import { clearOpsLogQueue, flushOpsLogQueue, getOpsLogData, submitOpsEvent } from '../ops-log/ops-log-api'
import { getPageSessionEvent } from './page-session-duration'

export const setupBeacon = () => {
  if ('onpagehide' in self) {
    addEventListener('pagehide', sendBeacon, { capture: true })
  } else {
    // only register beforeunload/unload in browsers that don't support pagehide to avoid breaking bfcache
    addEventListener('unload', sendBeacon, { capture: true })
    addEventListener('beforeunload', sendBeacon, { capture: true })
  }

  // visibilitychange may be your last opportunity to beacon, though the user could come back later
  addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      sendBeacon()
    }
  }, { capture: true })
}

const minBeaconDiff = 1_000
let lastBeacon

const sendBeacon = () => {
  if (lastBeacon == null || timeNow() - lastBeacon > minBeaconDiff) {
    submitOpsEvent(getPageSessionEvent())
  }
  const data = getOpsLogData()
  if (!data.events.length) {
    return // Queue has been handled in the meantime
  }
  lastBeacon = timeNow()
  if (typeof window?.navigator?.sendBeacon === 'function') {
    try {
      const headers = { type: 'application/json' }
      const json = JSON.stringify(data)
      const blob = new Blob([json], headers)
      console.log('sendBeacon >', json)
      if (window.navigator.sendBeacon(`${axios.defaults.baseURL}/ops-logs`, blob)) {
        console.log('sendBeacon <')
        clearOpsLogQueue() // Clear the queue to avoid duplicate calls from multiple window callbacks
        return
      }
    } catch (exception) {
      console.log('sendBeacon X', exception)
      // fallback below
    }
  }
  // noinspection JSIgnoredPromiseFromCall
  flushOpsLogQueue() // Fallback to XHR
  clearOpsLogQueue() // Clear the queue to avoid duplicate calls from multiple window callbacks
}
