import { intersection } from 'lodash-es'
import { getCurrentUser } from '../shared/local-storage'
import { useMyUserLazy } from './user-api'

export const getCurrentUserFullName = () => {
  const user = getCurrentUser()
  return user && `${user.firstName} ${user.lastName}`
}

export const hasRole = (...roles) => intersection(getCurrentUser()?.roles, roles).length

export const hasModule = (...modules) => intersection(getCurrentUser()?.modules, modules).length

const passwordChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

export const generatePassword = () =>
  [...Array(8)]
    .map(() => passwordChars.charAt(Math.floor(Math.random() * passwordChars.length)))
    .join('')

export const useVerifyLoggedIn = () => {
  const { refetch: reloadMyUser } = useMyUserLazy()
  return async () => {
    if (!getCurrentUser()) {
      await reloadMyUser()
    }
  }
}
