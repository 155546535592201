import { watch } from 'vue'
import { errorToasts, newErrorToast } from './error-toast-state'

const delay = 10_000

watch(newErrorToast, () => {
  if (newErrorToast.value) {
    const error = typeof newErrorToast.value === 'string' ? { text: newErrorToast.value } : newErrorToast.value
    if (!errorToasts.value.some(({ text }) => text === error.text)) {
      errorToasts.value = [...errorToasts.value, error]
      window.setTimeout(removeErrorToast(error), delay)
    }
    newErrorToast.value = null
  }
})

export const removeErrorToast = error => () => {
  errorToasts.value = errorToasts.value
    .filter(({ text }) => text !== error.text)
}
