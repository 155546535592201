import { ref } from 'vue'

const titleSuffix = ref('')
const titleSeparator = ref(' - ')

export const setupTitle = ({ suffix = '', separator = ' - ' }) => {
  titleSuffix.value = suffix
  titleSeparator.value = separator
}

export const useTitle = () => {
  const setTitle = value => {
    document.title =
      (value ?? '') +
      (value && titleSuffix.value ? titleSeparator.value : '') +
      (titleSuffix.value ?? '')
  }
  return { setTitle }
}
