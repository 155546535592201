import { module } from '../module/module-state'

export const mds =
  import.meta.glob('./content/**/*.md', { query: '?raw', import: 'default' })

export const hashIndex = '01-index'

export const hashOrIndex = hash => hash || `${module.value}/${hashIndex}`

const prefixLength = './content/'.length
const suffixLength = '.md'.length

export const toHash = fileName => {
  const hash = fileName.substring(prefixLength, fileName.length - suffixLength)
  return hash === hashIndex ? '' : hash
}

export const loadMd = async hash => {
  // console.log('DocsScreen.vue loadMd >', hash)
  const fileName = `./content/${hash}.md`
  // console.log('DocsScreen.vue loadMd fileName', fileName)
  const loader = mds[fileName]
  if (!loader) {
    throw new Error('Error loading file ' + fileName)
  }
  return await loader()
}

export const getHeading = async hash => {
  const md = await loadMd(hash)
  const firstLine = md.substring(0, md.indexOf('\n'))
  return firstLine.substring(firstLine.indexOf('#') + 1).trim()
}
