import { queryOptions, useQuery } from '@tanstack/vue-query'
import { delete_, getVersioned, patch as patchApi, update as updateApi } from '../shared/api'
import { keepQueryData, toUrl } from './shared'

export const singletonResource = resource => {
  const singletonUrl = () => toUrl(resource)

  const singletonKey = () => [singletonUrl()]

  const getSingleton = async () => await getVersioned(singletonUrl())

  const updateSingleton = async (version, data, settings) =>
    await updateApi(singletonUrl(), version, data, null, settings)

  const patchSingleton = async (version, data, settings) =>
    await patchApi(singletonUrl(), version, data, null, settings)

  const deleteSingleton = async (version, settings) =>
    await delete_(singletonUrl(), version, null, settings)

  const singletonOptions = () => queryOptions({
    queryKey: singletonKey,
    queryFn: () => getSingleton(),
    ...keepQueryData
  })

  const useSingleton = options => useQuery({
    ...singletonOptions(),
    ...options
  })

  return {
    singletonUrl,
    singletonKey,
    getSingleton,
    updateSingleton,
    patchSingleton,
    deleteSingleton,
    singletonOptions,
    useSingleton
  }
}
