import { throttle, timeNow } from './fns'
import { opsLogApplicationIdentifier } from './system-state'

const events = ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel']
const idleTimeout = 60 * 1_000 // 1 minute
let idleTime = 0
let lastActive = null
let timer

const getOpenIdleTime = () => lastActive ? timeNow() - lastActive : 0

export const setupIdleMeasurement = () => {
  const reset = () => {
    if (lastActive) {
      idleTime += getOpenIdleTime()
      lastActive = null
    }
    window.clearTimeout(timer)
    timer = window.setTimeout(() => {
      lastActive = timeNow()
    }, idleTimeout)
  }

  const onEvent = throttle(reset, 200)
  for (const event of events) {
    window.addEventListener(event, onEvent, { passive: true })
  }
  document.addEventListener('visibilitychange', () => {
    if (!document.hidden) {
      onEvent()
    }
  })
  reset()
}

const pageSessionDuration = () => Math.round(timeNow() - (idleTime + getOpenIdleTime()))

export const getPageSessionEvent = () => ({
  severity: 'info',
  activity: 'Application',
  identifier: opsLogApplicationIdentifier,
  name: 'pageSession',
  data: { duration: pageSessionDuration() }
})
