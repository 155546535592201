import { computed, ref } from 'vue'
import numbro from 'numbro'
import numbroLanguages from 'numbro/dist/languages.min'
import { createI18n, useI18n } from 'vue-i18n'
import { getPersistentSettings, setPersistentSetting } from '../shared/local-storage'

export const locales = {
  en: 'English',
  cs: 'Česky',
  nl: 'Dutch'
}

Object.values(numbroLanguages).forEach(language => numbro.registerLanguage(language))

const getUserLanguage = () => window.navigator.language

const coerceUserLanguage = userLanguage =>
  Object.keys(locales)
    .find(locale => userLanguage.startsWith(locale)) ?? Object.keys(locales)[0]

const languageKey = 'language'

const getStoredLanguage = () => getPersistentSettings()[languageKey]

const setStoredLanguage = language => {
  setPersistentSetting(languageKey, language)
}

const setupNumbro = userLanguage => {
  const language = Object.keys(numbro.languages()).find(supportedLanguage => supportedLanguage.startsWith(userLanguage))
  if (language) {
    numbro.setLanguage(language)
  }
  // console.log('i18n.js setupNumbro', { userLanguage, language, used: numbro.language() })
}

export const setupVueI18n = () => createI18n({
  fallbackLocale: 'en'
})

export const initI18n = () => {
  const userLanguage = getStoredLanguage() ?? coerceUserLanguage(getUserLanguage())
  // console.log('i18n.js initI18n', { userLanguage })

  setupNumbro(userLanguage)
  const vueI18n = setupVueI18n()
  setI18nLanguage(vueI18n.global.locale, userLanguage)

  return { vueI18n }
}

export const loadI18nMessages = async vueI18n => {
  // Load using import() fn to avoid confusing Jest
  const promises =
    Object.keys(locales)
      .map(locale => import(`./locales/${locale}.yaml`))
  const bundles =
    (await Promise.all(promises))
      .map(module => module.default)
  bundles.forEach((bundle, index) => {
    vueI18n.global.setLocaleMessage(Object.keys(locales)[index], bundle)
  })
}

const currentLocaleRef = ref()

export const currentLocale = computed(() => currentLocaleRef.value)

export const setI18nLanguage = (i18nLocale, language) => {
  i18nLocale.value = language
  currentLocaleRef.value = language
  setStoredLanguage(language)
  document.querySelector('html').setAttribute('lang', language)
}

export const tl = prefix => {
  const { t } = useI18n()
  return (code, ...rest) => t(`${prefix}.${code}`, ...rest)
}
