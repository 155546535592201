import { watch } from 'vue'
import { getCurrentUser, getPersistentSettings, setPersistentSetting } from '../shared/local-storage'
import { module } from './module-state'

const lastModule = 'lastModule'

watch(module, () => {
  setPersistentSetting(lastModule, module.value)
})

const getLastModule = () => getPersistentSettings()[lastModule]

const currentUserHasModule = module => getCurrentUser()?.modules?.includes(module)

export const setDefaultModule = () => {
  module.value = currentUserHasModule(getLastModule()) ? getLastModule() : getCurrentUser()?.modules?.[0]
}
