import { randomUUID } from './fns'

const { localStorage } = window

const tokenItem = 'token'
export const getToken = () => localStorage.getItem(tokenItem)
export const setToken = token => localStorage.setItem(tokenItem, token)
export const clearToken = () => localStorage.removeItem(tokenItem)

const authUserItem = 'auth-user'
export const getCurrentUser = () => JSON.parse(localStorage.getItem(authUserItem))
export const setCurrentUser = user => localStorage.setItem(authUserItem, JSON.stringify(user))
export const clearCurrentUser = () => localStorage.removeItem(authUserItem)

const requestedPathnameItem = 'requested-pathname'
export const getRequestedPathname = () => {
  const data = JSON.parse(localStorage.getItem(requestedPathnameItem))
  if (data?.userId === getCurrentUser().id) {
    return data.requestedPathname
  }
}
export const setRequestedPathname =
  requestedPathname => localStorage.setItem(requestedPathnameItem,
    JSON.stringify({ requestedPathname, userId: getCurrentUser()?.id }))
export const clearRequestedPathname = () => localStorage.removeItem(requestedPathnameItem)

const settings = 'settings'
export const getPersistentSettings = () => JSON.parse(localStorage.getItem(settings)) ?? {}
export const setPersistentSetting = (key, value) =>
  localStorage.setItem(settings, JSON.stringify({ ...getPersistentSettings(), [key]: value }))

const deviceIdItem = 'deviceId'
export const getDeviceId = () => {
  let deviceId = localStorage.getItem(deviceIdItem)
  if (!deviceId) {
    deviceId = randomUUID()
    setDeviceId(deviceId)
  }
  return deviceId
}
export const setDeviceId = deviceId => localStorage.setItem(deviceIdItem, deviceId)
// export const clearDeviceId = () => localStorage.removeItem(deviceIdItem)
