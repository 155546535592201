import axios from 'axios'
import { getToken } from './shared/local-storage'

axios.defaults.baseURL = '/api'

export const setAuthentication = () => {
  const token = getToken()
  if (token) {
    axios.defaults.auth = { username: token, password: '' }
  }
}
export const clearAuthentication = () => {
  axios.defaults.auth = null
}
export const hasAuthentication = () => axios.defaults.auth != null

export const anonymous = fn => (...args) => {
  clearAuthentication()
  try {
    return fn(...args)
  } finally {
    setAuthentication()
  }
}

// Delay all Axios requests to show loading
// axios.interceptors.request.use(config =>
//   new Promise(resolve => setTimeout(() => resolve(config), 1000))) // * Math.random()
