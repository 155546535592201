import { keepPreviousData } from '@tanstack/vue-query'

export const toUrl = (...parts) => '/' + parts.join('/')

export const stableQuery = { refetchOnWindowFocus: false }
export const lazyQuery = { enabled: false }
export const keepQueryData = { placeholderData: keepPreviousData }
export const immutableDataQuery = { staleTime: Infinity }
export const dataDrivenInfiniteQuery = pageSize => ({
  initialPageParam: 0,
  getNextPageParam: (lastPage, allPages, lastPageParam) =>
    lastPage.length < pageSize ? undefined : lastPageParam + 1
})
