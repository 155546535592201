import { cloneDeep, debounce } from 'lodash-es'
import { getCurrentUser, getDeviceId } from '../shared/local-storage'
import { opsLogApplication, pageSessionId } from '../shared/system-state'
import { resource } from '../resource'

const flushIntervalSec = 60
const deviceId = getDeviceId()
let queue = []
let flushing = false

export const submitOpsEvent = event => {
  queue.push({
    createTime: new Date().toISOString(),
    ...event
  })
  // console.log('ops-log-api.js submitOpsEvent', queue.length, event)
  if (event.severity === 'error') {
    debouncedFlushPriority()
  } else {
    debouncedFlushStandard()
  }
}

const cloneQueue = () => cloneDeep(queue)

export const getOpsLogData = queue => ({
  application: opsLogApplication,
  deviceId,
  userId: getCurrentUser()?.id,
  pageSessionId,
  events: queue ?? cloneQueue()
})

export const clearOpsLogQueue = () => {
  queue = []
}

const { create, useInfiniteList } = resource('ops-logs')

export const flushOpsLogQueue = async () => {
  if (flushing) {
    return // Prevent multiple concurrent flushes
  }
  if (!queue.length) {
    return // Queue has been handled in the meantime
  }
  // console.log('ops-log-api.js flushOpsLogQueue >')
  flushing = true
  const prevQueue = cloneQueue()
  try {
    const data = getOpsLogData(prevQueue)
    clearOpsLogQueue() // Clear the queue for new events after create() below starts
    try {
      await create(data, { opsLog: false })
    } catch (error) {
      queue = [...prevQueue, ...queue] // Restore cleared events into the queue
      // Otherwise do nothing, all the events will be handled in the following flush
    }
  } finally {
    flushing = false
  }
  // console.log('ops-log-api.js flushOpsLogQueue <')
}

const debouncedFlushStandard = debounce(flushOpsLogQueue, 1_000 * flushIntervalSec)
const debouncedFlushPriority = debounce(flushOpsLogQueue, 1_000)

const pageSize = 100

export const useOpsLogs = params => useInfiniteList(pageSize, params)
