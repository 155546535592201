<script setup>
import { onBeforeUnmount, onMounted, ref, toRefs, watch } from 'vue'
import { Toast } from 'bootstrap'
import { removeErrorToast } from './error-toast-logic'

const props = defineProps({
  error: { type: Object, required: true }
})
const { error } = toRefs(props)

const toastElement = ref()
const toast = ref()

onMounted(() => {
  toast.value = new Toast(toastElement.value)
  toast.value.show()
})

const handleClose = () => {
  removeErrorToast(error.value)()
}

watch(toastElement, () => {
  toastElement.value.addEventListener('hidden.bs.toast', handleClose)
})
onBeforeUnmount(() => {
  toastElement.value.removeEventListener('hidden.bs.toast', handleClose)
  toast.value.hide()
})
</script>

<template>
  <div
    ref="toastElement"
    class="toast"
    data-bs-autohide="false"
  >
    <div class="toast-header bg-danger bg-opacity-10">
      <h5 class="me-auto text-dark mb-0">
        {{ error.title || 'Server Error' }}
      </h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="toast"
      />
    </div>
    <div class="toast-body bg-danger bg-opacity-25">
      {{ error.text }}
    </div>
    <div
      v-if="error.action"
      class="toast-body bg-danger bg-opacity-10 pt-2 border-top"
    >
      <a
        type="button"
        class="btn btn-outline-primary btn-sm"
        :href="error.action.url"
      >
        {{ error.action.label }}
      </a>
    </div>
  </div>
</template>
