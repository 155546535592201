import { create } from '../shared/api'
import { resource } from '../resource'
import { lazyQuery, stableQuery } from '../resource/shared'
import { anonymous } from '../api-config'

export const {
  getList: getUsers,
  getOne: getUser,
  useList: useUsers,
  useOneStable: useUserStable,
  create: createUser,
  update: updateUser,
  archive: archiveUser,
  restore: restoreUser,
  patch: patchUser
} = resource('users')

export const setUserPassword = async (id, version, password) => {
  await patchUser(id, version, { password })
}

const myUserResource = resource('my-user')

const { useSingleton: useMyUserSingleton, patchSingleton: patchMyUser } = myUserResource

export const { updateMyUser } = myUserResource

export const useMyUserLazy = () => useMyUserSingleton(lazyQuery)

export const useMyUser = () => useMyUserSingleton({
  ...stableQuery,
  staleTime: 5_000
})

export const updateMyUserPassword = async (version, password) => {
  await patchMyUser(version, { password })
}

export const requestPasswordReset = async (data, options) =>
  await anonymous(create)('/password-reset-tokens', data, null, options)

export const resetPassword = async (token, data, options) =>
  await anonymous(create)(`/password-reset-tokens/${token}`, data, options)
