import { toValue } from 'vue'
import { queryOptions, useQuery } from '@tanstack/vue-query'
import { delete_, getVersioned, patch as patchApi, update as updateApi } from '../shared/api'
import { stableQuery, toUrl } from './shared'

export const oneResource = resource => {
  const oneUrl = id => toUrl(resource, toValue(id))

  const oneKey = id => [toUrl(resource), id] // keep id reactive

  const getOne = async id => await getVersioned(oneUrl(id))

  const update = async (id, version, data, settings) =>
    await updateApi(oneUrl(id), version, data, null, settings)

  const patch = async (id, version, data, settings) =>
    await patchApi(oneUrl(id), version, data, null, settings)

  const createPatch = (data, settings) => async (id, version) =>
    await patch(id, version, data, settings)

  const archive = createPatch({ archived: true })

  const restore = createPatch({ archived: false })

  const deleteOne = async (id, version, settings) =>
    await delete_(oneUrl(id), version, null, settings)

  const oneOptions = id => queryOptions({
    queryKey: oneKey(id),
    queryFn: () => getOne(id),
    enabled: () => toValue(id) != null
  })

  const useOne = (id, options) =>
    useQuery({
      ...oneOptions(id),
      ...options
    })

  const useOneStable = (id, options) =>
    useOne(id, { ...stableQuery, ...options })

  return {
    oneUrl,
    oneKey,
    getOne,
    update,
    patch,
    createPatch,
    archive,
    restore,
    deleteOne,
    oneOptions,
    useOne,
    useOneStable
  }
}
