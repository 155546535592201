import { toValue } from 'vue'
import { queryOptions, useInfiniteQuery, useQuery } from '@tanstack/vue-query'
import { toQuery } from '../shared/fns'
import { create as apiCreate, get } from '../shared/api'
import { dataDrivenInfiniteQuery, keepQueryData, stableQuery, toUrl } from './shared'

const listConfig = params => {
  const query = toQuery(params)
  return Object.keys(query).length ? { params: query } : null
}

export const listResource = resource => {
  const listUrl = () => toUrl(resource)

  const listKey = params => [listUrl(), params]

  const getList = async params => await get(listUrl(), listConfig(params))

  const create = async (data, settings) =>
    await apiCreate(listUrl(), data, null, settings)

  const listOptions = params => queryOptions({
    queryKey: listKey(params),
    queryFn: () => getList(params),
    ...keepQueryData
  })

  const infiniteListOptions = (pageSize, params) => queryOptions({
    queryKey: listKey(params),
    queryFn: ({ pageParam }) => getList({
      ...params,
      page: toValue(pageParam),
      size: pageSize
    }),
    ...dataDrivenInfiniteQuery(pageSize)
  })

  const useList = (params, options) => useQuery({
    ...listOptions(params),
    ...options
  })

  const useListStable = (params, options) =>
    useList(params, { ...stableQuery, ...options })

  const useInfiniteList = (pageSize, params, options) => useInfiniteQuery({
    ...infiniteListOptions(pageSize, params),
    ...options
  })

  return {
    listUrl,
    listKey,
    getList,
    create,
    listOptions,
    infiniteListOptions,
    useList,
    useListStable,
    useInfiniteList
  }
}
