import { randomUUID } from './fns'
import { pageSessionId } from './system-state'

let requestId

export const setRequestId = () => {
  requestId = randomUUID()
  return requestId
}

export const withRequestIds = config =>
  ({ ...config, headers: { ...config?.headers, 'X-Request-ID': requestId, 'X-Session-ID': pageSessionId } })
