<template>
  <img
    alt="Mathrail logo"
    src="../assets/mathrail_logo.svg"
    class="logo"
  >
</template>

<style scoped>
.logo {
  height: 1.5rem
}
</style>
