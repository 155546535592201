import { extractErrorParams } from './fns'
import { submitOpsEvent } from '../ops-log/ops-log-api'
import { genericOpsEventBase, genericRouteOpsEventData } from '../ops-log/ops-log-logic'

export const vueErrorHandler = (error, instance, info) => {
  const name = instance?.$options?.__name
  const file = instance?.$options?.__file
  const event = {
    ...genericOpsEventBase(),
    severity: 'error',
    name: 'exception',
    data: {
      error: extractErrorParams(error),
      ...genericRouteOpsEventData(),
      component: { name, file, info }
    }
  }
  submitOpsEvent(event)
  console.error('Uncaught FE exception:', error)
}

const errorCounts = {}
const maxErrorCount = 10

export const editorErrorHandler = error => {
  const event = {
    ...genericOpsEventBase('Editor'),
    severity: 'error',
    name: 'exception',
    data: {
      source: 'Editor',
      error: extractErrorParams(error),
      ...genericRouteOpsEventData()
    }
  }
  const { stack } = event.data.error
  if (stack) {
    const count = errorCounts[stack] ?? 0
    if (count < maxErrorCount) {
      errorCounts[stack] = count + 1
      submitOpsEvent(event)
    }
  } else {
    submitOpsEvent(event)
  }
  console.error('Uncaught editor exception:', error)
}
