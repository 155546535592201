import { computed, ref } from 'vue'
import { last } from 'lodash-es'
import { randomUUID } from './fns'

const domain = 'mathrail.com'

export const environment = ref()

export const setEnvironment = () => {
  const { hostname } = window.location
  environment.value =
    ['localhost', '127.0.0.1', 'frontend'].includes(hostname) // frontend: in-Docker Cypress tests (Gitlab)
      ? 'local'
      : hostname?.includes('staging') ? 'staging' : 'production'
}

const docsEnvPrefixes = {
  local: 'local.',
  staging: 'staging.',
  production: ''
}
export const docsUrlPrefix = computed(() =>
  `https://docs.${docsEnvPrefixes[environment.value]}${domain}`
)

const userFilesEnvPrefixes = {
  local: 'local.',
  staging: 'staging.',
  production: ''
}
export const userFilesUrlPrefix = computed(() =>
  `https://user-files.${userFilesEnvPrefixes[environment.value]}${domain}/files`
)

export const opsLogApplication =
  window.location.pathname.startsWith('/layout-snapshots') ? 'layoutSnapshot' : 'frontend'
export const opsLogApplicationIdentifier =
  opsLogApplication === 'layoutSnapshot' ? last(window.location.pathname.split('/')) : null
export const pageSessionId = randomUUID()
