<script setup>
import { onBeforeUnmount, ref, watch } from 'vue'
import { Toast } from 'bootstrap'
import { userMessage } from './user-message-state'

const toastElement = ref()
const toast = ref()

const handleClose = () => {
  userMessage.value = null
}

watch(toastElement, () => {
  toastElement.value.addEventListener('hidden.bs.toast', handleClose)
})
onBeforeUnmount(() => {
  toastElement.value.removeEventListener('hidden.bs.toast', handleClose)
})

watch(userMessage, () => {
  if (userMessage.value) {
    toast.value = new Toast(toastElement.value)
    toast.value.show()
  } else {
    toast.value.hide()
  }
})

const classes = {
  error: 'text-danger',
  info: 'text-primary'
}

const labels = {
  error: 'Error',
  info: 'Info'
}
</script>

<template>
  <div class="position-fixed top-0 start-50 translate-middle-x mt-5 mtr-on-top">
    <div
      ref="toastElement"
      class="toast mt-3"
    >
      <div class="toast-header">
        <h5 class="me-auto mb-0">
          <i
            class="bi-square-fill"
            :class="classes[userMessage?.type]"
          />
          {{ labels[userMessage?.type] }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="toast"
        />
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="toast-body"
        v-html="userMessage?.content"
      />
      <!-- eslint-enable -->
    </div>
  </div>
</template>
